import { FC } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Box, Paper, Typography } from "@mui/material";
import { Message } from "../../../types/chat";

dayjs.extend(utc);
dayjs.extend(timezone);

const timeZone = dayjs.tz.guess();

const MessageBox: FC<{ message: Message }> = ({ message }) => {
  // Determine styles based on message role
  const getStyles = (role: string) => {
    switch (role) {
      case "assistant":
        return {
          backgroundColor: "#0a14cc",
          color: "white",
          justifyContent: "flex-end",
        };
      case "information":
        return {
          backgroundColor: "#4caf50", // A green-like color for information
          color: "white",
          justifyContent: "center",
        };
      default:
        return {
          backgroundColor: "#f0f0f0",
          color: "black",
          justifyContent: "flex-start",
        };
    }
  };

  const styles = getStyles(message.role);

  return message.visible ? (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={styles.justifyContent}
      alignItems="center"
    >
      <Paper
        style={{
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: styles.backgroundColor,
          color: styles.color,
          maxWidth: "70%",
          lineBreak: "anywhere",
        }}
      >
        <Typography variant="body1">{message.content}</Typography>
        <Typography variant="caption">
          {dayjs
            .utc(message.timestamp)
            .tz(timeZone)
            .format("DD/MM/YYYY HH:mm:ss")}
        </Typography>
      </Paper>
    </Box>
  ) : null;
};

export default MessageBox;
