import { useState } from "react";
import { useParams } from "react-router-dom";

import { useGetChat } from "../../api/chats/get";
import { useSendMessage } from "../../api/chats/post";

import ChatBox from "../../common/components/ChatBox/ChatBox";
import TextInput from "../../common/components/ChatBox/TextInput";

function UserChat() {
  const { id } = useParams();
  const { data: chatData } = useGetChat(id as string);
  const { mutateAsync: sendMessage, isPending } = useSendMessage(id as string);

  const [message, setMessage] = useState("");

  const handleSendMessage = async () => {
    if (!message) return;
    const data = await sendMessage({ id: id as string, params: { message } });
    console.log(data);
    setMessage("");
  };

  return (
    <>
      <ChatBox messages={chatData || []} />
      <TextInput
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onSubmit={handleSendMessage}
        isLoading={isPending}
      />
    </>
  );
}

export default UserChat;
