import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Chat from "../pages/Chat/Chats";
import UserChat from "../pages/Chat/UserChat";
import Login from "../pages/Login/Login";
import Scenarios from "../pages/Scenario/Scenarios";
import { useGetLoginStatus } from "../api/login/get";
import Layout from "../common/components/Layout/Layout";
import LoginRoute from "./LoginRoute";

const AppRoutes = () => {
  const { data: loginData } = useGetLoginStatus();
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<Layout />}>
          <Route
            path="/chats"
            element={
              <LoginRoute>
                <Chat />
              </LoginRoute>
            }
          />
          <Route
            path="/chat/:id"
            element={
              <LoginRoute>
                <UserChat />
              </LoginRoute>
            }
          />
          <Route
            path="/scenarios"
            element={
              <LoginRoute>
                <Scenarios />
              </LoginRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
