import { FC } from "react";
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
} from "@mui/material";
import { styled } from "@mui/system";

import { BasicTableColumn, BasicTableRow } from "../../../types/table";

type BasicTableProps = {
  columns: BasicTableColumn[];
  rows: BasicTableRow[];
  onRowClick?: (id: string) => void;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  flexDirection: "column",
  borderRadius: "16px",
  maxWidth: "80%",
  maxHeight: "80vh",
  padding: "20px",
  margin: "auto",
  marginTop: "20px",
  marginBottom: "20px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
}));

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  fontWeight: 600,
}));

const BasicTable: FC<BasicTableProps> = ({ columns, rows, onRowClick }) => {
  const handleRowClick = (row: BasicTableRow) => {
    if (onRowClick) {
      onRowClick(row._id);
    }
  };

  return (
    <TableContainer component={StyledPaper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCellHead key={column.id} align={column.align}>
                {column.label}
              </StyledTableCellHead>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row._id} onClick={() => handleRowClick(row)}>
              {columns.map((column) => (
                <TableCell key={`${row._id}-${column.id}`} align={column.align}>
                  {row[column.id]}
                </TableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
