import React, { useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useGetLoginStatus } from "../api/login/get";

interface LoginRouteProps {
  children: ReactNode;
  redirectTo?: string;
  enabled?: boolean;
}

const LoginRoute: React.FC<LoginRouteProps> = ({
  children,
  redirectTo = "/login",
  enabled = true,
}) => {
  const { data: loginStatus, isLoading } = useGetLoginStatus();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !loginStatus && enabled) {
      navigate(redirectTo);
    }
  }, [loginStatus, isLoading, enabled, navigate, redirectTo]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return loginStatus && enabled ? <>{children}</> : null;
};

export default LoginRoute;
