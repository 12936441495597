import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { API_URL } from "../constants";
import { ScenarioListItem } from "../../types/scenarios";

const getScenarios = async () => {
  const url = `${API_URL}/scenarios/`;
  const { data } = await axios.get(url, { withCredentials: true });
  return data;
};

const useGetScenarios = () => {
  return useQuery<ScenarioListItem[]>({
    queryKey: ["scenarios"],
    queryFn: getScenarios,
  });
};

export default useGetScenarios;
