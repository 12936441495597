import { FC, KeyboardEvent } from "react";
import {
  styled,
  Box,
  Button,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import { Send } from "@mui/icons-material";

const MessageInput = styled(OutlinedInput)(() => ({
  width: "98%",
  margin: "auto",
  marginTop: 5,
  "&.MuiOutlinedInput-root": {
    borderRadius: "20px",
  },
}));

const SubmitInput = styled(Button)(() => ({
  borderRadius: "20px",
  height: "55px",
  backgroundColor: "#0a14cc",
  width: "2%",
  margin: "auto",
}));

const MainInputContainer = styled(Box)(() => ({
  display: "flex",
  width: "80%",
  justifyContent: "center",
  alignItems: "center",
  margin: "0.5% auto",
  gap: "2%",
}));

const Loading = styled(CircularProgress)(() => ({
  color: "white",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const TextInput: FC<{
  value: string;
  isLoading?: boolean;
  onChange: (e: any) => void;
  onSubmit: () => void;
}> = ({ value, isLoading = false, onChange, onSubmit }) => {
  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <MainInputContainer>
      <MessageInput
        value={value}
        onChange={onChange}
        onKeyUp={handleKeyPress}
        placeholder="Type a message..."
        type="text"
        autoFocus
        disabled={isLoading}
      />
      <SubmitInput variant="contained" onClick={onSubmit} disabled={!value}>
        {isLoading ? <Loading /> : <Send />}
      </SubmitInput>
    </MainInputContainer>
  );
};

export default TextInput;
