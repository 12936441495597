import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";

import { API_URL } from "../constants";
import { NewChat } from "../../types/chat";

const getNewChat = async (params: NewChat) => {
  const url = `${API_URL}/chats/`;
  const { data } = await axios.post(url, params, { withCredentials: true });
  return data;
};

const getChat = async (id: string) => {
  const url = `${API_URL}/chats/${id}`;
  const { data } = await axios.get(url, { withCredentials: true });
  return data;
};

const getChats = async () => {
  const url = `${API_URL}/chats/`;
  const { data } = await axios.get(url, { withCredentials: true });
  return data;
};

const useGetChats = () => {
  return useQuery({
    queryKey: ["chats"],
    queryFn: getChats,
    staleTime: 1000 * 60 * 5,
  });
};

const useGetNewChat = () => {
  return useMutation({
    mutationFn: getNewChat,
    onError: (error) => {
      console.error(error);
    },
  });
};

const useGetChat = (id: string) => {
  return useQuery({
    queryKey: ["chat", id],
    queryFn: () => getChat(id),
    staleTime: 1000 * 60 * 5,
  });
};

export { useGetNewChat, useGetChat, useGetChats };
