import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";

import { API_URL } from "../constants";
import { LoginRequest, LoginResponse } from "../../types/login";

const getLogin = async (params: LoginRequest) => {
  const url = `${API_URL}/login/`;
  const { data } = await axios.post(url, params, { withCredentials: true });
  return data;
};

const getLoginStatus = async () => {
  const url = `${API_URL}/login/`;
  const { data } = await axios.get(url, { withCredentials: true });
  return data;
};

const useGetLogin = () => {
  return useMutation({
    mutationFn: getLogin,
    onSuccess: (data: LoginResponse) => {
      localStorage.setItem("token", data.token);
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

const useGetLoginStatus = () => {
  return useQuery({
    queryKey: ["loginStatus"],
    queryFn: getLoginStatus,
  });
};

export { useGetLogin, useGetLoginStatus };
