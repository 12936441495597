import { FC, useState } from "react";
import {
  Box,
  Input,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

import { useGetLogin, useGetLoginStatus } from "../../api/login/get";

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  flexDirection: "column",
  borderRadius: "16px",
  width: "30%",
  height: "50vh",
  padding: "20px",
  margin: "auto",
  marginTop: "20px",
}));

const StyledInput = styled(Input)(({ theme }) => ({
  width: "100%",
  marginBottom: "20px",
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Bebas Neue",
  fontSize: "2em",
  fontWeight: 400,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "50%",
  backgroundColor: "#0a14cc",
  color: "white",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#0d19ff",
  },
}));

const Login: FC = () => {
  const navigateTo = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { mutateAsync: getLogin, isPending } = useGetLogin();
  const { data: loginData } = useGetLoginStatus();

  const handleLogin = async () => {
    try {
      await getLogin({ username, password });
      setPassword("");
      setUsername("");
      navigateTo("/chats");
    } catch (error) {
      console.error(error);
    }
  };

  if (loginData) {
    navigateTo("/chats");
  }

  return (
    <StyledPaper elevation={3}>
      <Box>
        <StyledTitle>Login</StyledTitle>
      </Box>
      <Box>
        <StyledInput
          type="text"
          placeholder="Email"
          onChange={(e) => setUsername(e.target.value)}
        />
        <StyledInput
          type="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <StyledButton onClick={handleLogin}>
          {isPending ? <CircularProgress /> : "Login"}
        </StyledButton>
      </Box>
    </StyledPaper>
  );
};

export default Login;
