import React from "react";
import { styled } from "@mui/system";
import { Outlet } from "react-router-dom";

import Navbar from "../NavBar/NavBar";
import { useGetLoginStatus } from "../../../api/login/get";

const Root = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100vh",
  width: "100%",
});

const Content = styled("main")({
  position: "relative",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  overflowX: "hidden",
  justifyContent: "center",
});

const Container = styled("div")({
  flex: 1,
  height: "fit-content",
});

const NavBarContainer = styled("div")({
  height: "10%",
});

export default function Layout() {
  return (
    <Root>
      <NavBarContainer>
        <Navbar />
      </NavBarContainer>
      <Content>
        <Container id="root-container">
          <Outlet />
        </Container>
      </Content>
    </Root>
  );
}
