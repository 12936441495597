import { useNavigate } from "react-router-dom";

import { useGetChats } from "../../api/chats/get";
import { BasicTableColumn } from "../../types/table";
import BasicTable from "../../common/components/BasicTable/BasicTable";

const columns: BasicTableColumn[] = [
  { id: "name", label: "Chat Name", align: "left" },
  { id: "_id", label: "Chat ID", align: "center" },
  { id: "user_id", label: "User ID", align: "center" },
];

function Chat() {
  const navigate = useNavigate();
  const { data: chatData } = useGetChats();

  const handleNewChat = async (id: string) => {
    navigate(`/chat/${id}`);
  };

  return (
    <BasicTable
      columns={columns}
      rows={chatData || []}
      onRowClick={handleNewChat}
    />
  );
}

export default Chat;
