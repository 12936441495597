import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { API_URL } from "../constants";

const sendMessage = async (id: string, params: any) => {
  const url = `${API_URL}/chats/${id}`;
  const { data } = await axios.post(url, params, { withCredentials: true });
  return data;
};

const useSendMessage = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: { id: string; params: any }) =>
      sendMessage(variables.id, variables.params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["chat", id],
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

export { useSendMessage };
