import { FC } from "react";
import { Box, Button } from "@mui/material";
import { LoginOutlined, LogoutOutlined } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

import { useGetLoginStatus } from "../../../api/login/get";
import { useLogout } from "../../../api/login/delete";

const NavbarRoot = styled("nav")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  verticalAlign: "middle",
  width: "100%",
  height: "75px",
  margin: "auto",
  backgroundColor: "#0a14cc",
  borderRadius: "0 0 8px 8px",
  boxShadow: "0 0 10px 10px rgba(0, 0, 0.1, 0.3)",
});

const NavbarButton = styled(Button)({
  color: "white",
  textDecoration: "none",
  marginLeft: "5px",
  marginRight: "5px",
  "&:hover": {
    backgroundColor: "#0d19ff",
  },
});

const Navbar: FC = () => {
  const navigateTo = useNavigate();
  const { data: loginData } = useGetLoginStatus();
  const { mutateAsync: logout } = useLogout();

  const handleLogout = async () => {
    await logout();
    window.location.href = "/login";
  };

  return (
    <NavbarRoot>
      <Box>
        <NavbarButton onClick={() => navigateTo("/chats")}>Chats</NavbarButton>
        <NavbarButton onClick={() => navigateTo("/scenarios")}>
          Scenarios
        </NavbarButton>
      </Box>
      <Box>
        {loginData ? (
          <NavbarButton onClick={handleLogout}>
            <LogoutOutlined />
          </NavbarButton>
        ) : (
          <NavbarButton href="/login">
            <LoginOutlined />
          </NavbarButton>
        )}
      </Box>
    </NavbarRoot>
  );
};

export default Navbar;
