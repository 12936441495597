import axios from "axios";
import { useMutation } from "@tanstack/react-query";

import { API_URL } from "../constants";

const logout = async () => {
  const url = `${API_URL}/login/`;
  const { data } = await axios.delete(url, { withCredentials: true });
  return data;
};

const useLogout = () => {
  return useMutation({
    mutationFn: logout,
    onSuccess: () => {
      localStorage.removeItem("token");
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

export { useLogout };
