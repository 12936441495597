import { FC, useEffect, useRef } from "react";
import { styled, Paper } from "@mui/material";

import MessageBox from "./MessageBox";
import { Message } from "../../../types/chat";

const Chat = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  gap: "10px",
  height: "75vh",
  overflowY: "auto",
  width: "80%",
  margin: "auto",
  borderRadius: "15px",
}));

const ChatBox: FC<{ messages: Message[] }> = ({ messages }) => {
  const endOfMessagesRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <Chat>
      {messages.map((message, index) => (
        <MessageBox key={index} message={message} />
      ))}
      <div ref={endOfMessagesRef} />
    </Chat>
  );
};

export default ChatBox;
