import { useNavigate } from "react-router-dom";

import BasicTable from "../../common/components/BasicTable/BasicTable";
import useGetScenarios from "../../api/scenarios/get";
import { BasicTableColumn } from "../../types/table";
import { useGetNewChat } from "../../api/chats/get";

const columns: BasicTableColumn[] = [
  { id: "scenario", label: "Name", align: "left" },
  { id: "nature_of_illness", label: "Nature", align: "center" },
  { id: "_id", label: "ID", align: "center" },
];

function Scenarios() {
  const navigate = useNavigate();
  const { data: test } = useGetScenarios();
  const { mutateAsync: getNewChat } = useGetNewChat();

  const handleNewChat = async (id: string) => {
    const newChat = await getNewChat({ scenario_id: id });
    if (newChat) {
      navigate(`/chat/${newChat.id}`);
    }
  };

  return (
    <BasicTable
      columns={columns}
      rows={test || []}
      onRowClick={handleNewChat}
    />
  );
}

export default Scenarios;
